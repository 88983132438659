<template>
  <div class="page" :class="[isMobile ? 'mobile' : '']">
    <!-- <pre class="dev" v-if="isMobile">
      Versions:
      <button @click="scrollVersion = 1">V1</button>
      <button @click="scrollVersion = 2">V2</button>
      <button @click="scrollVersion = 3">V3</button>
    </pre> -->
    <div class="main-block">
      <div class="block block__left">
        <header>
          <img src="@/assets/logo.svg" alt="INTEA Logo"/>
          <h1>ADVENT CALENDAR | 2023</h1>
        </header>
        <div class="card" :class="`card-${displayDay.dayNr}`">
          <div class="card__image">
            <transition name="fade" mode="out-in">
              <img :src="require(`@/assets/day-${displayDay.dayNr}.jpg`)" :key="displayDay.dayNr"/>
            </transition>
          </div>
          <div class="card__text">
            <h2 v-html="displayDay.heading"></h2>
            <span v-html="displayDay.subHeading"></span>
            <p v-html="displayDay.firstP"></p>
            <p v-html="displayDay.secondP"></p>
          </div>
        </div>
      </div>
      <div class="block block__right calendar">
        <button v-if="scrollVersion === 1" @click="scrollPrev" class="scroll-button scroll-up">
          <svg
          class="arrow-1" xmlns="http://www.w3.org/2000/svg" width="94" height="18" viewBox="0 0 94 18" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M47.0009 1.06734L92.6685 17.689L93.0106 16.7493L47.0103 0.0065646L47.0081 0.000548024L47.0009 0.00315779L46.9922 0L46.9896 0.00728001L0.989746 16.7499L1.33177 17.6895L47.0009 1.06734Z" fill="#BB1A56"/>
          </svg>
          <svg
          class="arrow-2" xmlns="http://www.w3.org/2000/svg" width="94" height="18" viewBox="0 0 94 18" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M47.0009 1.06734L92.6685 17.689L93.0106 16.7493L47.0103 0.0065646L47.0081 0.000548024L47.0009 0.00315779L46.9922 0L46.9896 0.00728001L0.989746 16.7499L1.33177 17.6895L47.0009 1.06734Z" fill="#BB1A56"/>
          </svg>
          <svg
          class="arrow-3" xmlns="http://www.w3.org/2000/svg" width="94" height="18" viewBox="0 0 94 18" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M47.0009 1.06734L92.6685 17.689L93.0106 16.7493L47.0103 0.0065646L47.0081 0.000548024L47.0009 0.00315779L46.9922 0L46.9896 0.00728001L0.989746 16.7499L1.33177 17.6895L47.0009 1.06734Z" fill="#BB1A56"/>
          </svg>
        </button>
        <button v-if="scrollVersion === 2" @click="scrollPrev" class="scroll-button-2 scroll-up-2"></button>
        <div class="calendar__container" ref="calendarContainer">
          <div
            v-for="(day, index) in calendarDays"
            ref="calendarDay"
            :key="day.dayNr"
            class="calendar__day"
            :class="[dayStatus(index), `calendar__day--${day.dayNr}`]"
            @click="setCurrentDay(index)">
            <div class="calendar__day--number">
              {{ day.dayNr }}<br>
              <span>December</span>
            </div>
            <div
              class="calendar__day--image"
              v-bind:style="{ 'background-image': 'url(' + require(`@/assets/day-${day.dayNr}.jpg`) + ')' }">
            </div>
          </div>
        </div>
        <button v-if="scrollVersion === 1" @click="scrollNext" class="scroll-button scroll-down">
          <svg
          class="arrow-1" xmlns="http://www.w3.org/2000/svg" width="94" height="18" viewBox="0 0 94 18" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M47.0009 16.6221L92.6685 0.000455856L93.0106 0.940149L47.0103 17.6829L47.0081 17.6889L47.0009 17.6863L46.9922 17.6895L46.9896 17.6822L0.989746 0.939602L1.33177 -9.15527e-05L47.0009 16.6221Z" fill="#BB1A56"/>
          </svg>
          <svg
          class="arrow-2" xmlns="http://www.w3.org/2000/svg" width="94" height="18" viewBox="0 0 94 18" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M47.0009 16.6221L92.6685 0.000455856L93.0106 0.940149L47.0103 17.6829L47.0081 17.6889L47.0009 17.6863L46.9922 17.6895L46.9896 17.6822L0.989746 0.939602L1.33177 -9.15527e-05L47.0009 16.6221Z" fill="#BB1A56"/>
          </svg>
          <svg
          class="arrow-3" xmlns="http://www.w3.org/2000/svg" width="94" height="18" viewBox="0 0 94 18" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M47.0009 16.6221L92.6685 0.000455856L93.0106 0.940149L47.0103 17.6829L47.0081 17.6889L47.0009 17.6863L46.9922 17.6895L46.9896 17.6822L0.989746 0.939602L1.33177 -9.15527e-05L47.0009 16.6221Z" fill="#BB1A56"/>
          </svg>
        </button>
        <button v-if="scrollVersion === 2" @click="scrollNext" class="scroll-button-2 scroll-down-2"></button>
      </div>
    </div>
    <footer>
      <div class="socials">
        <a href="https://www.instagram.com/intea_elearning/" target="_blank" class="button button__ig"></a>
        <a href="https://www.facebook.com/inteaelearning/" target="_blank" class="button button__fb"></a>
      </div>
      <p class="copyright">Visualizations were created by each member of the INTEA team,
        using the capabilities of the Midjourney AI platform. </p>
    </footer>
  </div>
</template>

<script>
/* eslint-disable max-len */
export default {
  name: 'MainPage',
  props: {},
  data() {
    return {
      transitionsOn: false,
      scrollVersion: 2,
      isMobile: false,
      initialData: {
        dayNr: 0,
        heading: `With Christmas upon us,`,
        subHeading: '',
        firstP: `<span class="hl">we think it's time to reflect and think about the little tips and tricks that have 
          helped us navigate the e-learning turbulent waters in this 2023 that we are about to leave behind.</span>`,
        secondP: `Here's a little gift from INTEA: Our very own calorie-free Advent Calendar.
          24 learning tips for you to enjoy every day before the Holidays.<br><br>Merry Christmas!`,
      },
      displayDay: {
        dayNr: 0,
        heading: `With Christmas upon us,`,
        subHeading: '',
        firstP: `<span class="hl">we think it's time to reflect and think about the little tips and tricks that have 
          helped us navigate the e-learning turbulent waters in this 2023 that we are about to leave behind.</span>`,
        secondP: `Here's a little gift from INTEA: Our very own calorie-free Advent Calendar.
          24 learning tips for you to enjoy every day before the Holidays.<br><br>Merry Christmas!`,
      },
      calendarDays: [{
        dayNr: 0,
        heading: `With Christmas upon us,`,
        subHeading: '',
        firstP: `<span class="hl">we think it's time to reflect and think about the little tips and tricks that have 
          helped us navigate the e-learning turbulent waters in this 2023 that we are about to leave behind.</span>`,
        secondP: `Here's a little gift from INTEA: Our very own calorie-free Advent Calendar.
          24 learning tips for you to enjoy every day before the Holidays.<br><br>Merry Christmas!`,
      }, {
        dayNr: 1,
        heading: `1`,
        subHeading: `<span>December</span>`,
        firstP: `<b>The Comfy Learning Lair</b>`,
        secondP: `Create a "learning lair" that's so comfy you'll want to spend time in it - not only for a cup of coffee, but for learning as well.`,
      }, {
        dayNr: 2,
        heading: `2`,
        subHeading: `<span>December</span>`,
        firstP: `<b>Meow-tivational Alarms</b>`,
        secondP: `Set a learning schedule, and consider setting your alarm to the sound of a motivational cat meowing. It's a purrfect way to get in the right learning mood.`,
      }, {
        dayNr: 3,
        heading: `3`,
        subHeading: `<span>December</span>`,
        firstP: `<b>The Cone of Silence</b>`,
        secondP: `Noise-canceling headphones: Because who needs to hear the neighbor's drilling orchestra?`,
      }, {
        dayNr: 4,
        heading: `4`,
        subHeading: `<span>December</span>`,
        firstP: `<b>Befriend Your Router</b>`,
        secondP: `A reliable internet connection is your lifeline. Make friends with your router; you'll be spending a lot of time together.`,
      }, {
        dayNr: 5,
        heading: `5`,
        subHeading: `<span>December</span>`,
        firstP: `<b>Your Stretch Goals</b>`,
        secondP: `Take breaks to stretch and exercise. Your body should be trained and ready to carry the new knowledge stored in your brain!`,
      }, {
        dayNr: 6,
        heading: `6`,
        subHeading: `<span>December</span>`,
        firstP: `<b>Mix it up!</b>`,
        secondP: `Experiment with various learning styles like a mad scientist with test tubes—minus the explosions.`,
      }, {
        dayNr: 7,
        heading: `7`,
        subHeading: `<span>December</span>`,
        firstP: `<b>Don't blink!</b>`,
        secondP: `Dr. Who references aside... Actually, please do! Your eyes are not only the window to your soul, but also one of the most precious learning tools you have. Take care of them!`,
      }, {
        dayNr: 8,
        heading: `8`,
        subHeading: `<span>December</span>`,
        firstP: `<b>Keep it up(dated)!</b>`,
        secondP: `Keep your computer and browser up to date. You should always be in sync with your learning instruments.`,
      }, {
        dayNr: 9,
        heading: `9`,
        subHeading: `<span>December</span>`,
        firstP: `<b>A throne worth of your behind</b>`,
        secondP: `Invest in a chair that feels like a fluffy cloud. You're basically on a knowledge journey to the skies.`,
      }, {
        dayNr: 10,
        heading: `10`,
        subHeading: `<span>December</span>`,
        firstP: `<b>The Mind Vault</b>`,
        secondP: `Keep your learning notes like you're preparing yourself for an Extreme Trivial Pursuit Deathmatch game. You never know when that knowledge will come in handy!`,
      }, {
        dayNr: 11,
        heading: `11`,
        subHeading: `<span>December</span>`,
        firstP: `<b>Internet Wisdom Hunt</b>`,
        secondP: `Seek out extra resources like YouTube videos and discussion forums. Sometimes, the information you seek is hidden in plain sight. You need to look for it.`,
      }, {
        dayNr: 12,
        heading: `12`,
        subHeading: `<span>December</span>`,
        firstP: `<b>Virtual Hide and Seek</b>`,
        secondP: `Online study groups are great, but be ready for a virtual game of hide and seek when it's your group's turn to present.`,
      }, {
        dayNr: 13,
        heading: `13`,
        subHeading: `<span>December</span>`,
        firstP: `<b>Pace yourself</b>`,
        secondP: `The only pace you're racing is the one set by the mermaid song of your new favorite Netflix series calling you from the living room. So, take it easy.`,
      }, {
        dayNr: 14,
        heading: `14`,
        subHeading: `<span>December</span>`,
        firstP: `<b>Don't forget the Pit Stops</b>`,
        secondP: `We know you are thirsty for knowledge, but don't neglect your thirst for water. And while we're at it, don't forget to get some sun too (if available). We're basically houseplants with complex emotions.`,
      }, {
        dayNr: 15,
        heading: `15`,
        subHeading: `<span>December</span>`,
        firstP: `<b>Be a Doer</b>`,
        secondP: `Do you know when you've spent the last 10 minutes explaining the rules of your new complicated board game and someone tells you "Let's just start and learn the rules as we go"? Sometimes, the best way of getting into a new, exciting, learning topic is to just jump right in and start doing.`,
      }, {
        dayNr: 16,
        heading: `16`,
        subHeading: `<span>December</span>`,
        firstP: `<b>Sharing is... Learning</b>`,
        secondP: `From the dawn of civilization, information transfer has been a social matter. Picture a tribe gathered around a bonfire, listening to a story told by the village Elder. Our fashion choices might have changed since then, but the principle remains the same. Share your knowledge with others, and welcome the chances you get to enjoy someone else's story.`,
      }, {
        dayNr: 17,
        heading: `17`,
        subHeading: `<span>December</span>`,
        firstP: `<b>Go your own way</b>`,
        secondP: `Learning is not a 9 to 5 thing. We learn everywhere, all the time. Did you always want to know about the migration patterns of Monarch Butterflies? Congrats! Nowadays, you can. Always find time to learn something new outside of your daily routine.`,
      }, {
        dayNr: 18,
        heading: `18`,
        subHeading: `<span>December</span>`,
        firstP: `<b>Embrace the Rabbit Hole</b>`,
        secondP: `You know when you are reading a Wikipedia article about The Beatles and a couple of hours later you find yourself reading about the use of fermented fish sauce in Ancient Rome? That's normal when learning. Don't worry. Enjoy the trip, wherever it takes you.`,
      }, {
        dayNr: 19,
        heading: `19`,
        subHeading: `<span>December</span>`,
        firstP: `<b>Challenge Accepted!</b>`,
        secondP: `Set goals that are achievable but still challenging, like not devouring that Ben and Jerry's ice cream tub that you just bought in one sitting. I mean...theoretically, it's possible.`,
      }, {
        dayNr: 20,
        heading: `20`,
        subHeading: `<span>December</span>`,
        firstP: `<b>♫ We can dance if we want to ♫</b>`,
        secondP: `Celebrate your achievements with a victory dance that could rival the ones Ryan Gosling did in Barbie. You are more than Kenough!`,
      }, {
        dayNr: 21,
        heading: `21`,
        subHeading: `<span>December</span>`,
        firstP: `<b>ILT Be Back</b>`,
        secondP: `We are all comfy learning from home in our pyjamas, but Instructor-Led Training opportunities are still out there, in the wild. Maybe it's time to venture into the learning streets again for a new adventure!`,
      }, {
        dayNr: 22,
        heading: `22`,
        subHeading: `<span>December</span>`,
        firstP: `<b>Use all the crayons in the box</b>`,
        secondP: `Dive into all the resources your e-learning platform offers. It's like a digital treasure chest of wisdom.`,
      }, {
        dayNr: 23,
        heading: `23`,
        subHeading: `<span>December</span>`,
        firstP: `<b>Most of the times, less is more</b>`,
        secondP: `We all love page-turner long novels, but in e-learning, long walls of text are usually hurdles in the way of learners. As Kevin Malone, from The Office, wisely said: why waste time say lot word when few word do trick?`,
      }, {
        dayNr: 24,
        heading: `24`,
        subHeading: `<span>December</span>`,
        firstP: `<b>Long Live Lifelong Learning!</b>`,
        secondP: `You can't trust all quotes on the Internet (Abraham Lincoln, 1862), but apparently, Albert Einstein once said "A day without learning is a day wasted". Let's make this our mantra! Learning is Growing!`,
      }],
    };
  },
  mounted() {
    this.onResize();
    window.addEventListener('resize', this.onResize, { passive: true });
    if (localStorage.getItem('it-initial') === 'yes') {
      const currentDate = new Date();
      const currentDay = currentDate.getDate();
      this.setCurrentDay(currentDay);
    } else {
      this.setCurrentDay(0);
      localStorage.setItem('it-initial', 'yes');
    }
  },
  computed: {
    transitionName() {
      return this.transitionsOn ? 'fade' : 'disabled';
    },
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth <= 1024;
      if (this.isMobile) {
        const dayElementHeight = this.$refs.calendarDay[0].scrollHeight;
        this.$refs.calendarContainer.style.maxHeight = `${dayElementHeight * 6 + 10}px`;
      } else {
        this.$refs.calendarContainer.style.maxHeight = '';
      }
    },
    scrollPrev() {
      const container = this.$el.querySelector('.calendar__container');
      container.scrollBy({
        left: 0,
        top: -250,
        behavior: 'smooth',
      });
    },
    scrollNext() {
      const container = this.$el.querySelector('.calendar__container');
      container.scrollBy({
        left: 0,
        top: 250,
        behavior: 'smooth',
      });
    },
    setCurrentDay(day) {
      const currentDate = new Date();
      const currentDay = currentDate.getDate();
      if (day > currentDay && currentDate.getFullYear() === 2023) return;

      const container = this.$el.querySelector('.calendar__container');
      if (day > this.calendarDays.length && day !== 0) {
        [this.displayDay] = this.calendarDays.slice(-1);
        container.scrollBy(0, this.$el.querySelector(`.calendar__day--24`).offsetTop);
      } else {
        let scrollToElement = (day > 0) ? day + 1 : 0;
        if (day >= 24) {
          scrollToElement = 24;
        }
        this.displayDay = this.calendarDays[day];
        this.$el.querySelector(`.calendar__day--${scrollToElement}`).scrollIntoView({
          block: 'nearest',
          behavior: 'smooth',
        });
      }
      if (!this.transitionsOn) this.transitionsOn = true;
    },
    dayStatus(elementDay) {
      const currentDate = new Date();
      const currentDay = currentDate.getDate();
      let dayStatus = '';
      if (currentDate.getFullYear() > 2023) {
        return 'past';
      }
      if (currentDay >= 24 && elementDay === 25) {
        return 'present';
      }
      if (elementDay < currentDay) {
        dayStatus = 'past';
      } else if (elementDay === currentDay) {
        dayStatus = 'present';
      } else {
        dayStatus = 'future';
      }
      return dayStatus;
    },
  },
};
/* eslint-disable */
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

@mixin fluid-type($min-font-size: 12px, $max-font-size: 21px, $lower-range: 420px, $upper-range: 900px) {
  font-size: calc(#{$min-font-size} + #{(($max-font-size / ($max-font-size * 0 + 1)) - ($min-font-size / ($min-font-size * 0 + 1)))} * ( (100vw - #{$lower-range}) / #{(($upper-range / ($upper-range * 0 + 1)) - ($lower-range / ($lower-range * 0 + 1)))}));
  @media screen and (max-width: $lower-range) {
    font-size: $min-font-size;
  }
  @media screen and (min-width: $upper-range){
    font-size: $max-font-size;
  }
}
// mobile()
//   @media (max-width: 768px)
//     {block}
@mixin mobile {
  @media (max-width: 1024px) { @content; }
}
@mixin mobileThin {
  @media (max-width: 430px) { @content; }
}
@keyframes arrowUp {
  0% {
    transform: translateY(10px);
    opacity: 0;
  }
  50% {
    transform: translateY(0px);
    opacity: 1;
  }
  100% {
    transform: translateY(-20px);
    opacity: 0;
  }
}
@keyframes arrowDown {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }
  50% {
    transform: translateY(0px);
    opacity: 1;
  }
  100% {
    transform: translateY(20px);
    opacity: 0;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

$inteaBlue: #223F99;
$inteaRed: #BB1A56;
$inteaGrey: #2E313D;

.dev {
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  margin: 0px;
}
.page {
  footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 56%;
    padding: 0% 4%;
    color: $inteaGrey;
    @include mobile {
      width: 100%;
    }
    p {
      text-align: right;
    }
    .socials {
      display: flex;
      flex: 1 0 25%;
      .button {
        display: inline-block;
        height: 42px;
        width: 42px;
        border: none;
        @include mobile {
          height: 32px;
          width: 32px;
        }
        &__fb {
          background: center / contain no-repeat url(~@/assets/icon-fb.svg);
        }
        &__ig {
          background: center / contain no-repeat url(~@/assets/icon-ig.svg);
          margin-right: 25px;
        }
      }
    }
    .copyright {
      flex: 0 0 75%;
      font-weight: 300;
      @include fluid-type(12px, 16px, 768px, 1600px);
    }
  }  
}

.main-block {
  display: flex;
  align-items: flex-start;
  font-family: 'Noto Sans', sans-serif;
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 63%;
    margin: 3% 0%;
    @include mobile {
      width: calc(100vw - 13%);
    }
    @include mobileThin {
      width: calc(100vw - 7%)
    }
    img {
      @include mobileThin {
        height: 60%;
      }
    }
    h1 {
      font-family: 'Noto Sans';
      font-weight: 300;
      @include fluid-type(21px, 48px, 600px, 1920px);
      color: $inteaBlue;
      margin: 0px;
      @include mobileThin {
        font-size: 16px;
      }
    }
  }
  .block {
    min-width: 0px;
    &__left {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      flex: 0 0 80%;
      padding: 0 0 0 4%;
      align-self: stretch;
      @include mobile {
        flex: 0 0 85%;
        padding: 0 3%;
      }
    }
    &__right {
      flex: 0 0 20%;
      justify-content: flex-end;
      padding-right: 1%;
      padding-top: 1%;
      @include mobile {
        flex: 0 0 15%;
        padding-top: 0%;
      }
    }
  }
  .card {
    display: flex;
    align-items: flex-start;
    @include mobile {
      flex-wrap: wrap;
    }
    &__image {
      flex: 1 0 63%;
      width: 100%;
      height: 0;
      position: relative;
      @include mobile {
        flex: 0 0 100%;
        padding-bottom: 91%;
      }
      img {
        width: 100%;
        border-radius: 25px;
        max-height: 800px;
        object-fit: cover;
      }
    }
    &__text {
      flex: 1 0 37%;
      padding: 0% 3%;
      @include mobile {
        padding: 0px;
        margin-top: 25px;
        padding-right: 35px;
      }
      h2 {
        font-family: 'Noto Serif Display';
        font-size: 58px;
        font-weight: 800;
        color: $inteaRed;
        @include fluid-type(28px, 120px, 420px, 1600px);
        line-height: 100%;
        margin-top: 0px;
        margin-bottom: 0px;
        @include mobile {
          display: none;
        }
      }
      span {
        @include fluid-type(21px, 28px, 420px, 1920px);
        font-family: 'Noto Sans';
        font-weight: 300;
        color: $inteaRed;
        @include mobile {
          display: none;
        }
      }
      p {
        font-family: 'Noto Sans';
        font-size: 28px;
        font-weight: 300;
        line-height: 38px;
        @include fluid-type(21px, 20px);
        line-height: normal;
        color: $inteaGrey;
        @include mobile() {
          margin-top: 0px;
        }
        .hl {
          color: #BB1A56;
        }
      }
    }
    &.card-0 {
      .card__image {
        @include mobile {
          padding-bottom: 100%;
        }
      }
      .card__text {
        h2 {
          @include fluid-type(36px, 58px, 420px, 1600px);
          margin-bottom: 0px;
          @include mobile {
            display: inline-block;
            margin-bottom: 20px;
          }
        }
        span {
          @include mobile {
            display: inline-block;
          }
        }
      }
    }
  }
  .calendar {
    .scroll-button {
      display: none;
      background: transparent;
      border: none;
      position: relative;
      height: 18px;
      width: 100%;
      &.scroll-button {
        &:hover{
          svg {
            animation-play-state: paused;
            // animation: none;
          }
        }
        svg {
          position: absolute;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 10px;
          margin: auto;
          max-width: 100%;
        }
        .arrow-1 {
          animation-delay: 1s;
        }
        .arrow-2 {
          animation-delay: 2s;
        }
        .arrow-3 {
          animation-delay: 3s;
        }
      }
      &.scroll-up {
        svg {
          animation: arrowUp 3s ease-in infinite;
        }
      }
      &.scroll-down {
        svg {
          top: 10px;
          bottom: 0px;
          animation: arrowDown 3s ease-in infinite;
        }
      }
      @include mobile {
        display: block;
      }
    }
    .scroll-button-2 {
      display: none;
      width: 100%;
      border: none;
      height: 1px;
      background: $inteaRed;
      @include mobile {
        display: block;
      }
    }
    .scroll-up-2 {
      margin-bottom: 10px;
      // margin-top: 20px;
    }
    .scroll-down-2 {
      margin-top: 10px;
    }
    @include mobile {
      margin-top: calc(40px + 9%);
    }
    &__container {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      @include mobile {
        overflow-y: scroll;
        overflow-x: hidden;
        max-height: calc(100vh - 250px);
        scroll-snap-type: y mandatory;
        -webkit-overflow-scrolling: touch;
        scrollbar-width: 0px;
        &::-webkit-scrollbar {
            width: 0px;
        }
      }
    }
    &__day {
      flex: 1 0 31%;
      margin: 1px;
      position: relative;
      border-radius: 14px;
      overflow: hidden;
      cursor: pointer;
      &:nth-child(1) {
        &::before {
          display: none;
        }
        .calendar__day--number {
          display: none;
        }
      }
      &:nth-last-child(1) {
        flex-grow: 0;
      }
      @include mobile {
        flex: 0 0 99%;
        scroll-snap-align: center;
      }
      &::before {
        content: '';
        display: block;
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0px;
        left: 0px;
        background-color: $inteaBlue;
        opacity: 0.6;
        transition: opacity 0.2s;
      }      
      &.past {
        &::before {
          background-color: $inteaBlue;
        }
      }
      &.present {
        &::before {
          background-color: #fff;
          border: 2px solid $inteaRed;
          border-radius: 0.9rem;
          opacity: 1;
        }
        .calendar__day--number {
          color: $inteaRed;
        }
      }
      &.future {
        // opacity: 1;
        background-color: grey;
        &::before {
          background-color: $inteaRed;
        }
      }
      &--number {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-family: 'Noto Serif Display';
        font-weight: 800;
        color: #fff;
        @include fluid-type(28px, 40px, 420px, 1600px);
        transition: opacity 0.2s;
        line-height: .4;
        text-align: center;
        @include mobile {
          line-height: .6;
        }
        @include mobileThin {
          line-height: .4;
        }
        span {
          @include fluid-type(12px, 16px, 1024px, 1600px);
          font-family: 'Noto Sans';
          font-weight: 300;
          @include mobile {
            @include fluid-type(11px, 14px, 450px, 1024px);
          }
          @include mobileThin {
            font-size: 7px;
          }
        }
      }
      &.present {
        .calendar__day--number {
          span {
          }
        }
      }
      &.past,
      &.future {
        .calendar__day--number {
          span {
            display: none;
          }
        }
      }
      &--image {
        height: 0;
        width: 100%;
        background-size: 100% 100%;
        background-position: center;
        padding-bottom: 100%;
        max-height: 121px;
      }
      &.past,
      &.present {
        &:hover,
        &:focus,
        &:active {
          &::before,
          .calendar__day--number {
            opacity: 0;
          }
        }
      }
    }
  }
}

</style>
