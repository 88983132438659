<template>
  <MainPage/>
</template>

<script>
import MainPage from './components/MainPage.vue';

export default {
  name: 'App',
  components: {
    MainPage,
  },
};
/* eslint-disable */
</script>

<style lang="scss">

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Display:wght@600&family=Noto+Sans:wght@300;600;700&family=Noto+Serif+Display:wght@800&display=swap');
@import '~@/styles/_normalize.scss';

html {
  background: transparent url('~@/assets/bg.jpg') repeat center;
}

#app {
  /* max-width: 1280px; */
  margin: auto;
}
</style>
